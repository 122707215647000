import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TermsandconditionsPage from "../components/terms-and-conditions/terms-and-conditions"

const Termsandconditions = () => (
  <Layout>
    <Seo title="Terms and Conditions" />
    <TermsandconditionsPage />
  </Layout>
)

export default Termsandconditions
